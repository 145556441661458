var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "审核",
        visible: _vm.showDialog,
        top: "5vh",
        width: "1160px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "wrapper",
        },
        [
          _c("el-alert", {
            staticStyle: {
              width: "86%",
              "margin-top": "-70px",
              "margin-left": "100px",
            },
            attrs: { closable: false, title: _vm.titleTip, type: "success" },
          }),
          _c("div", { staticClass: "orderStatus" }, [
            _vm._v(_vm._s(_vm.orderState)),
          ]),
          _c(
            "el-form",
            { attrs: { model: _vm.data, inline: "", "label-width": "90px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户名称", prop: "cust_name" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.cust_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "cust_name", $$v)
                      },
                      expression: "data.cust_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人", prop: "contact" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.contact,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.data,
                          "contact",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "data.contact",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "dz",
                  attrs: { label: "地址", prop: "address" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "380px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.address,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.data,
                          "address",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "data.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动名称" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.info_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "info_name", $$v)
                      },
                      expression: "data.info_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "业务员" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.user_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "user_name", $$v)
                      },
                      expression: "data.user_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "签单金额" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.total_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "total_amount", $$v)
                      },
                      expression: "data.total_amount",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "已收款" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.receive_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "receive_amount", $$v)
                      },
                      expression: "data.receive_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "remark", $$v)
                      },
                      expression: "data.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table" },
            [
              _c("div", { staticStyle: { "line-height": "30px" } }, [
                _vm._v("存货详情"),
              ]),
              _c(
                "el-table",
                {
                  attrs: {
                    stripe: "",
                    data: _vm.inventoryData,
                    border: true,
                    "summary-method": _vm.getSummaries,
                    "show-summary": true,
                  },
                },
                [
                  _vm._l(_vm.inventoryColumns, function (item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        prop: item.prop,
                        width: item.width,
                        label: item.label,
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                item.label == "商品名称"
                                  ? [
                                      _c("goods-search", {
                                        ref: "goodsSearch",
                                        refInFor: true,
                                        attrs: {
                                          "f-key": row.goods_name_print,
                                        },
                                        on: {
                                          "add-rows": _vm.addRows,
                                          "select-goods-all": function (
                                            $event
                                          ) {
                                            return _vm.selectGoods($event, row)
                                          },
                                        },
                                      }),
                                    ]
                                  : item.label == "类型"
                                  ? [
                                      _c(
                                        "el-select",
                                        {
                                          model: {
                                            value: row.type,
                                            callback: function ($$v) {
                                              _vm.$set(row, "type", $$v)
                                            },
                                            expression: "row.type",
                                          },
                                        },
                                        _vm._l(
                                          _vm.typeList,
                                          function (typeItem, typeIndex) {
                                            return _c("el-option", {
                                              key: typeIndex,
                                              attrs: {
                                                label: typeItem.value,
                                                value: typeItem.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  : item.label == "单位"
                                  ? [
                                      _c(
                                        "el-select",
                                        {
                                          on: {
                                            change: function ($event) {
                                              return _vm.unitChange($event, row)
                                            },
                                          },
                                          model: {
                                            value: row.unit_id,
                                            callback: function ($$v) {
                                              _vm.$set(row, "unit_id", $$v)
                                            },
                                            expression: "row.unit_id",
                                          },
                                        },
                                        _vm._l(
                                          row.arr_unit,
                                          function (unitItem, unitIndex) {
                                            return _c("el-option", {
                                              key: unitIndex,
                                              attrs: {
                                                label: unitItem.unit_name,
                                                value: unitItem.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  : item.label == "存货价"
                                  ? [
                                      _c("el-input", {
                                        attrs: { type: "number" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.priceInput($event, row)
                                          },
                                        },
                                        model: {
                                          value: row.goods_price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              row,
                                              "goods_price",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "row.goods_price",
                                        },
                                      }),
                                    ]
                                  : item.label == "存货数量"
                                  ? [
                                      _c("el-input", {
                                        attrs: { type: "number" },
                                        on: {
                                          input: function ($event) {
                                            return _vm.numInput($event, row)
                                          },
                                        },
                                        model: {
                                          value: row.goods_num,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              row,
                                              "goods_num",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "row.goods_num",
                                        },
                                      }),
                                    ]
                                  : item.label == "金额"
                                  ? [
                                      _c("el-input", {
                                        model: {
                                          value: row.goods_money,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              row,
                                              "goods_money",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "row.goods_money",
                                        },
                                      }),
                                    ]
                                  : item.label == "备注"
                                  ? void 0
                                  : [_vm._v(_vm._s(row[item.prop]))],
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      align: "center",
                      label: "操作",
                      fixed: "right",
                      "min-width": "160px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleAddRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 添加 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleCopyRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 复制 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleDeleteRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _c("div", { staticStyle: { "line-height": "30px" } }, [
                _vm._v("收款记录"),
              ]),
              _c("base-table", {
                attrs: {
                  list: _vm.backData,
                  columns: _vm.backColumns,
                  "show-summary": true,
                  "is-border": true,
                  summary: _vm.backSummary,
                },
                on: {
                  "update:list": function ($event) {
                    _vm.backData = $event
                  },
                  "update:columns": function ($event) {
                    _vm.backColumns = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleReview } },
            [_vm._v("审核")]
          ),
          _c("el-button", [_vm._v("打印")]),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }