<template>
  <div class="wrapper">
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="预存货退货"
      :visible.sync="showDialog"
      top="5vh"
      width="1160px"
    >
      <div v-loading="loading" class="wrapper">
        <el-form :model="data" inline label-width="90px">
          <el-form-item label="客户名称" prop="cust_name">
            <el-input v-model="data.cust_name" disabled></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contact">
            <el-input v-model.trim="data.contact" disabled></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address" class="dz">
            <el-input
              v-model.trim="data.address"
              disabled
              style="width: 380px"
            ></el-input>
          </el-form-item>
          <el-form-item label="退货仓库">
            <!-- <el-input v-model="data.info_name" disabled></el-input> -->
            <el-select v-model="form.shck" style="width: 200px">
              <el-option
                v-for="(item, index) in depotList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="业务员">
            <!-- <el-input
              v-if="data.is_share == 0"
              v-model="data.user_name"
              disabled
            ></el-input> -->
            <el-select
              v-model="data.staff_id"
              clearable
              style="width: 140px"
              placeholder="请选择业务员"
            >
              <el-option
                v-for="(i, idx) in data.user_list"
                :key="idx"
                :value="i.id"
                :label="i.user_name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="送货人">
            <!-- <el-input v-model="data.total_amount" disabled></el-input> -->
            <el-select v-model="form.shr" style="width: 200px">
              <el-option
                v-for="(item, index) in shrList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div></div>
          <el-form-item label="存货单">
            <el-input v-model="data.receive_amount" disabled></el-input>
          </el-form-item>
        </el-form>
        <el-table
          stripe
          :data="returnData"
          :summary-method="getSummaries"
          :show-summary="true"
        >
          <el-table-column
            v-for="(item, index) in columns"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            align="center"
          >
            <template #default="{ row }">
              <div v-if="item.label == '单位'">
                <el-select
                  v-model="row.unit_id"
                  placeholder="单位"
                  @change="unitChange(row, $event)"
                >
                  <el-option
                    v-for="(unitItem, unitIndex) in row.unit_price"
                    :key="unitIndex"
                    :value="unitItem.unit_id"
                    :label="unitItem.unit_name"
                  ></el-option>
                </el-select>
              </div>
              <div v-else-if="item.label == '单价'">
                {{ row.price }}
              </div>
              <div v-else-if="item.label == '退货数量'">
                <el-input
                  v-model="row.goods_num"
                  type="number"
                  :min="0"
                  @input="moneyN(row)"
                ></el-input>
              </div>
              <!-- <div v-else-if="item.label == '金额'">
                {{ row.price * row.goods_num }}
              </div> -->
              <div v-else-if="item.label == '备注'">
                <el-input v-model="row.remark"></el-input>
              </div>
              <div v-else>
                {{ row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="100"
            fixed="right"
          >
            <template #default="{ row, $index }">
              <el-button type="text" @click="handleCopy(row, $index)">
                复制
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 底部按钮 -->
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 5px; text-align: right"
      >
        <el-button type="success" @click="handleAdd(3)">
          创建仓库直销单
        </el-button>

        <el-button type="warning" @click="handleAdd(2)">创建访销单</el-button>
        <!-- TODO[epic=预存货] 控制显示隐藏 -->
        <el-button
          v-if="form.allot_button == 1"
          type="primary"
          @click="handleAdd(1)"
        >
          仅创建访销单
        </el-button>
        <el-button @click="close">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { returnRowDetail, returnSubmit } from '@/api/createPreInventory'
  import { getAllSelect, driverList } from '@/api/advanceOrder'
  import { postAction } from '@/api/Employee'
  export default {
    name: '',
    components: {},
    data() {
      return {
        id: 0,
        showDialog: false,
        loading: true,
        data: {},
        form: {
          shck: '',
          shr: '',
        },
        depotList: [],
        shrList: [],
        // 换货表格
        returnData: [],
        columns: [
          {
            prop: 'type_text',
            label: '类型',
            width: '',
          },
          {
            prop: 'goods_name_print',
            label: '商品名称',
            width: '210',
          },
          {
            prop: 'goods_specs',
            label: '规格',
            width: '',
          },

          {
            prop: 'goods_left_num',
            label: '已还货数量',
            width: '',
          },
          {
            prop: 'goods_convert',
            label: '换算',
            width: '',
          },
          {
            prop: 'unit',
            label: '单位',
            width: '',
          },
          {
            prop: 'price',
            label: '单价',
            width: '',
          },
          {
            prop: 'goods_num',
            label: '退货数量',
            width: '',
          },
          {
            prop: 'goods_money',
            label: '金额',
            width: '',
          },
          {
            prop: 'remark',
            label: '备注',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.form.shck = ''
          this.form.shr = ''
          this.loading = true
        }
      },
    },
    created() {
      // 送货人下拉
      driverList().then((res) => {
        if (res.code == 200) {
          this.shrList = res.data
        }
      })
      // 出货仓库下拉
      getAllSelect().then((res) => {
        if (res.code == 200) {
          this.depotList = res.data.depot
        }
      })
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await postAction(
          '/promoteAdmin/goods-order/return-info-back',
          {
            order_id: this.id,
          }
        )
        if (code == 200) {
          this.data = data
          // 库存表格数据
          this.returnData = this.data.goods_list
          this.returnData.forEach((item) => {
            this.$set(item, 'price', item.unit_price[0].price)
            this.$set(item, 'unit_id', item.unit_price[0].unit_id)
            this.$set(item, 'goods_num', '0')
            this.$set(item, 'goods_money', '0')
            this.$set(item, 'remark', '')
          })
        }
        this.loading = false
      },
      unitChange(row, val) {
        if (val) {
          row.price = row.unit_price.filter(
            (item) => item.unit_id == val
          )[0].price
          this.moneyN(row)
        }
      },
      moneyN(row) {
        if (row.goods_num == '') {
          row.goods_num = 0
          return
        }
        row.goods_money = parseFloat(row.price) * parseFloat(row.goods_num)
      },
      handleCopy(row, index) {
        let r = JSON.parse(JSON.stringify(row))
        this.returnData.splice(index + 1, 0, r)
      },

      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        let num = 0
        data.map((item) => (num += Number(item.goods_money)))
        if (!num) {
          num = 0
        }
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总价'
            return
          }
          if (index === 3) {
            sums[index] = this.data.summary_left_unit
            return
          }
          if (index === 8) {
            sums[index] = num
            return
          }
          // this.summary.forEach((item) => {
          //   if (index === item.idx) {
          //     sums[index] = item.info
          //     return
          //   }
          // })
        })

        return sums
      },
      // strOn(value, row) {
      //   if (!Number(value)) {
      //     row.goods_num = parseFloat(value)
      //     // this.$message.error('请输入数字')
      //   } else {
      //   }
      // },
      close() {
        this.showDialog = false
      },
      handleAdd(only = 1) {
        let order_info = {
          staff_id: this.data.staff_id,
          depot_id: this.form.shck,
          deliver_id: this.form.shr,
          discounted_amount: '',
          remark: '',
        }

        let data = {
          cust_id: this.data.cust_id,
          order_id: this.id,
          order_info: JSON.stringify(order_info),
          goods_info: JSON.stringify(this.returnData),
          submit_type: only,
        }
        console.log(data)
        postAction('/promoteAdmin/goods-order/return-submit', data).then(
          (res) => {
            this.$message.success(res.msg)
            this.$emit('refresh')
            this.close()
          }
        )
      },
      handleAddDepot() {},
    },
  }
</script>
<style lang="scss" scoped></style>
