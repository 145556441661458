var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "预存货退货",
            visible: _vm.showDialog,
            top: "5vh",
            width: "1160px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "wrapper",
            },
            [
              _c(
                "el-form",
                {
                  attrs: { model: _vm.data, inline: "", "label-width": "90px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户名称", prop: "cust_name" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.data.cust_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "cust_name", $$v)
                          },
                          expression: "data.cust_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人", prop: "contact" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.data.contact,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.data,
                              "contact",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "data.contact",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "dz",
                      attrs: { label: "地址", prop: "address" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "380px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.data.address,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.data,
                              "address",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "data.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "退货仓库" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          model: {
                            value: _vm.form.shck,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "shck", $$v)
                            },
                            expression: "form.shck",
                          },
                        },
                        _vm._l(_vm.depotList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "业务员" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { clearable: "", placeholder: "请选择业务员" },
                          model: {
                            value: _vm.data.staff_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "staff_id", $$v)
                            },
                            expression: "data.staff_id",
                          },
                        },
                        _vm._l(_vm.data.user_list, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.user_name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "送货人" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          model: {
                            value: _vm.form.shr,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "shr", $$v)
                            },
                            expression: "form.shr",
                          },
                        },
                        _vm._l(_vm.shrList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "存货单" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.data.receive_amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "receive_amount", $$v)
                          },
                          expression: "data.receive_amount",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  attrs: {
                    stripe: "",
                    data: _vm.returnData,
                    "summary-method": _vm.getSummaries,
                    "show-summary": true,
                  },
                },
                [
                  _vm._l(_vm.columns, function (item, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: item.label,
                        prop: item.prop,
                        width: item.width,
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                item.label == "单位"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { placeholder: "单位" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.unitChange(
                                                  row,
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: row.unit_id,
                                              callback: function ($$v) {
                                                _vm.$set(row, "unit_id", $$v)
                                              },
                                              expression: "row.unit_id",
                                            },
                                          },
                                          _vm._l(
                                            row.unit_price,
                                            function (unitItem, unitIndex) {
                                              return _c("el-option", {
                                                key: unitIndex,
                                                attrs: {
                                                  value: unitItem.unit_id,
                                                  label: unitItem.unit_name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : item.label == "单价"
                                  ? _c("div", [
                                      _vm._v(" " + _vm._s(row.price) + " "),
                                    ])
                                  : item.label == "退货数量"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          attrs: { type: "number", min: 0 },
                                          on: {
                                            input: function ($event) {
                                              return _vm.moneyN(row)
                                            },
                                          },
                                          model: {
                                            value: row.goods_num,
                                            callback: function ($$v) {
                                              _vm.$set(row, "goods_num", $$v)
                                            },
                                            expression: "row.goods_num",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : item.label == "备注"
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          model: {
                                            value: row.remark,
                                            callback: function ($$v) {
                                              _vm.$set(row, "remark", $$v)
                                            },
                                            expression: "row.remark",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c("div", [
                                      _vm._v(
                                        " " + _vm._s(row[item.prop]) + " "
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      width: "100",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCopy(row, $index)
                                  },
                                },
                              },
                              [_vm._v(" 复制 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "5px", "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.handleAdd(3)
                    },
                  },
                },
                [_vm._v(" 创建仓库直销单 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.handleAdd(2)
                    },
                  },
                },
                [_vm._v("创建访销单")]
              ),
              _vm.form.allot_button == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleAdd(1)
                        },
                      },
                    },
                    [_vm._v(" 仅创建访销单 ")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }