<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="添加客户预存货"
    top="5vh"
    :visible.sync="showDialog"
    width="1000px"
  >
    <div class="wrapper">
      <el-form
        ref="form"
        inline
        :model="form"
        label-width="110px"
        label-position="right"
      >
        <el-form-item prop="info_id" label="预存活动:" style="width: 100%">
          <el-select
            v-model="form.info_id"
            placeholder="请选择预存活动"
            clearable
            :disabled="form.custom"
            style="width: 200px"
          >
            <el-option
              v-for="item in info_id_list"
              :key="item.info_id"
              :value="item.info_id"
              :label="item.info_name"
            ></el-option>
          </el-select>
          <el-checkbox v-model="form.custom" style="margin-left: 10px">
            自定义预存货
          </el-checkbox>
        </el-form-item>

        <el-form-item prop="staff_id" label="业务员:" style="width: 100%">
          <el-select
            v-model="form.staff_id"
            placeholder="请选择业务员"
            clearable
            style="width: 200px"
          >
            <el-option
              v-for="item in staff_id_list"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称:">
          <client-search
            ref="clientSearch"
            :popper-class="'selectDC'"
            style="width: 200px"
            :ziobj="{
              is_closed: 0,
            }"
            @select-val-alldata="selectAll"
          ></client-search>
        </el-form-item>
        <el-form-item label="联系人:" prop="contact">
          <el-input v-model="form.contact" disabled></el-input>
        </el-form-item>
        <el-form-item label="地址:" prop="address" style="width: 100%">
          <el-input
            v-model="form.address"
            disabled
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注:" style="width: 100%" prop="remark">
          <el-input v-model="form.remark" style="width: 400px"></el-input>
        </el-form-item>
        <el-form-item label="预存方案:" prop="detail_id" style="width: 100%">
          <el-select
            v-model="form.detail_id"
            placeholder="请选择方案"
            clearable
            :disabled="form.custom"
            style="width: 200px"
            @change="planChange"
          >
            <el-option
              v-for="item in planList"
              :key="item.detail_id"
              :value="item.detail_id"
              :label="item.detail_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-table v-loading="loading" stripe :data="list">
          <!-- 序号 -->
          <el-table-column align="center" type="index" width="50">
            <template slot="header">
              <el-popover popper-class="custom-table-checkbox" trigger="hover">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox
                    v-for="(item, index) in columns"
                    :key="index"
                    :label="item.label"
                  ></el-checkbox>
                </el-checkbox-group>
                <el-button slot="reference" type="text">
                  <vab-remix-icon icon="settings-line" />
                </el-button>
              </el-popover>
            </template>
          </el-table-column>
          <!-- 选择框 -->
          <el-table-column
            align="center"
            type="selection"
            width="50"
          ></el-table-column>
          <el-table-column
            v-for="(item, tableIndex) in finallyColumns"
            :key="tableIndex"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
          >
            <template #default="{ row }">
              <template v-if="item.label == '预存商品'">
                <div v-if="form.custom">
                  <goods-search
                    ref="goodsSearch"
                    :f-key="row.goods_name"
                    @add-rows="addRows"
                    @select-goods-all="selectGoods($event, row)"
                  ></goods-search>
                </div>
                <div v-else>
                  <span v-if="row.isAdd">
                    <goods-search
                      ref="goodsSearch"
                      :f-key="row.goods_name"
                      @select-goods-all="selectGoods($event, row)"
                    ></goods-search>
                  </span>
                  <span v-else>{{ row.goods_name }}</span>
                </div>
              </template>
              <template v-else-if="item.label == '类型'">
                <el-select v-model="row.type">
                  <el-option
                    v-for="(typeItem, typeIndex) in typeList"
                    :key="typeIndex"
                    :label="typeItem.value"
                    :value="typeItem.id"
                  ></el-option>
                </el-select>
              </template>
              <template v-else-if="item.label == '单位'">
                <div v-if="form.custom">
                  <el-select
                    v-model="row.unit_id"
                    @change="unitChange($event, row)"
                  >
                    <el-option
                      v-for="(unitItem, unitIndex) in row.arr_unit"
                      :key="unitIndex"
                      :label="unitItem.unit_name"
                      :value="unitItem.id"
                    ></el-option>
                  </el-select>
                </div>
                <div v-else>
                  <span v-if="row.isAdd">
                    <el-select
                      v-model="row.unit_id"
                      @change="unitChange($event, row)"
                    >
                      <el-option
                        v-for="(unitItem, unitIndex) in row.arr_unit"
                        :key="unitIndex"
                        :label="unitItem.unit_name"
                        :value="unitItem.id"
                      ></el-option>
                    </el-select>
                  </span>
                  <span v-else>{{ row.unit_name }}</span>
                </div>
              </template>
              <template v-else-if="item.label == '单价'">
                <el-input
                  v-model="row.goods_price"
                  @input="priceInput($event, row)"
                ></el-input>
              </template>
              <template v-else-if="item.label == '预存数量'">
                <el-input
                  v-model="row.goods_num"
                  @input="numInput($event, row)"
                ></el-input>
              </template>
              <template v-else-if="item.label == '金额'">
                <el-input v-model="row.goods_money"></el-input>
              </template>
              <template v-else-if="item.label == '备注'"></template>
              <template v-else>{{ row[item.prop] }}</template>
            </template>
          </el-table-column>
          <el-table-column
            prop=""
            align="center"
            label="操作"
            fixed="right"
            min-width="160px"
          >
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="handleCopyRow($index, row)"
              >
                复制
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handleDeleteRow($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item
          label=""
          prop=""
          style="margin-top: 10px; margin-left: 35px"
        >
          <el-checkbox
            v-model="form.exceed_status"
            :true-label="1"
            :false-label="0"
          >
            允许超收款下单
          </el-checkbox>
        </el-form-item>
        <el-form-item
          label="赠品设置:"
          style="display: block; margin-top: 15px"
        >
          <el-radio v-model="form.gift_type" label="1">签约即送</el-radio>
          <el-radio v-model="form.gift_type" label="2">全额收款后赠送</el-radio>
          <el-radio v-model="form.gift_type" label="3">全部还货后赠送</el-radio>
        </el-form-item>
        <div></div>
        <el-form-item label="签单金额:">
          <el-input v-model="form.total_amount" disabled></el-input>
        </el-form-item>
        <div></div>
        <el-form-item
          v-for="(item1, index1) in form.pay_data"
          :key="index1"
          label="收款金额:"
        >
          <div style="display: flex">
            <el-input
              v-model.trim="item1.money"
              style="width: 90px; margin-right: 20px"
            ></el-input>
            <el-select
              v-model="item1.pay_type"
              style="width: 90px; margin-right: 10px"
            >
              <el-option
                v-for="item_1 in allSelectList.arr_pay_type"
                :key="item_1.id"
                :label="item_1.name"
                :value="item_1.id"
              ></el-option>
            </el-select>
            <el-select v-model="item1.user_id" style="width: 90px">
              <el-option
                v-for="item_1 in selectPeopleList"
                :key="item_1.user_id"
                :label="item_1.user_name"
                :value="item_1.user_id"
              ></el-option>
            </el-select>
          </div>
          <i
            v-if="index1 == 0"
            class="el-icon-circle-plus addbtn"
            @click="addInput"
          ></i>
        </el-form-item>
      </el-form>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="handleSave(1)">保存并审核</el-button>
      <el-button type="primary" @click="handleSave(0)">保存</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  // 业务员下拉
  import { getStaffList } from '@/api/setPrice'
  import {
    addClientPre,
    activeSelectList,
    planSelectList,
    orderList,
    peopleList,
  } from '@/api/createPreInventory'
  import { getAllSelect } from '@/api/advanceOrder'
  import ClientSearch from '@/baseComponents/clientSearch'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  export default {
    name: 'Add',
    components: {
      ClientSearch,
      GoodsSearch,
    },
    data() {
      return {
        showDialog: false,
        loading: false,
        info_id_list: [],
        staff_id_list: [],
        allSelectList: [],
        planList: [],
        selectPeopleList: [],
        typeList: [
          {
            id: '1',
            value: '销常',
          },
          {
            id: '4',
            value: '赠品',
          },
        ],
        form: {
          custom: false,
          exceed_status: 0,
          info_id: '', //活动id,自定义时不传
          detail_id: '', //方案id,自定义时不传
          staff_id: '', //员工id
          cust_id: '', //客户id
          mobile: '', //电话
          contact: '', //联系人
          address: '', //地址
          gift_type: '1', //赠品方式1：签约即送，2：全额收款后赠送，3：全部还货后赠送
          total_amount: '', //总金额
          remark: '', //备注
          goods_data: '', //商品数据
          payData: { money: '', pay_type: '', user_id: '' }, //支付数据
          pay_data: [{ money: '', pay_type: '', user_id: '' }],
          check_status: '', //审核状态，审核保存1，未审核0
        },
        checkList: [
          '预存商品',
          '规格',
          '类型',
          '单位',
          '单价',
          '预存数量',
          '金额',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '预存商品',
            prop: 'goods_name',
            width: '220',
          },
          {
            order: 2,
            label: '规格',
            prop: 'goods_specs',
            width: '',
          },
          {
            order: 3,
            label: '类型',
            prop: 'goods_type',
            width: '',
          },
          {
            order: 4,
            label: '单位',
            prop: 'unit_name',
            width: '',
          },
          {
            order: 5,
            label: '单价',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 6,
            label: '预存数量',
            prop: 'goods_num',
            width: '',
          },
          {
            order: 7,
            label: '金额',
            prop: 'goods_money',
            width: '',
          },
          {
            order: 8,
            label: '备注',
            prop: 'goods_remark',
            width: '',
          },
        ],
        list: [],
      }
    },
    computed: {
      finallyColumns() {
        let arr = []
        this.checkList.forEach((item) => {
          arr.push(this.columns.filter((item2) => item2.label == item)[0])
        })
        return _.sortBy(arr, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (!val) {
          // this.form.custom = false
          this.clearForm()
        } else {
          // 预存货活动下拉
          activeSelectList().then((res) => {
            this.info_id_list = res.data
          })
        }
      },
      'form.custom'(val) {
        if (val) {
          this.form.info_id = ''
          this.list = []
          this.addRow()
        }
      },
      'form.info_id'(val) {
        this.form.detail_id = ''
        if (val) {
          // 方案下拉
          this.planList = this.info_id_list.filter(
            (item) => item.info_id == val
          )[0].detail
        } else {
          this.planList = []
        }
      },
    },
    created() {
      // 业务员下拉
      getStaffList().then((res) => {
        this.staff_id_list = res.data
      })
      // 收款人下拉
      peopleList().then((res) => {
        if (res.code == 200) {
          console.log(res.data)
          this.selectPeopleList = res.data
        }
      })
      // paytype 下拉
      getAllSelect().then((res) => {
        if (res.code == 200) {
          console.log(res.data)
          this.allSelectList = res.data
        }
      })
    },
    mounted() {},
    methods: {
      selectAll(val) {
        console.log(val)
        if (val !== '') {
          this.form.cust_id = val.id
          this.form.address = val.address
          this.form.mobile = val.mobile
          this.form.contact = val.boss + ' ' + val.mobile
        } else {
          this.form.cust_id = ''
          this.form.address = ''
          this.form.contact = ''
          this.form.mobile = ''
        }
      },
      clearForm() {
        this.$refs.form.resetFields()
        this.form.custom = false
        this.list = []
        this.form.total_amount = ''
        this.form.pay_data = [{ money: '', pay_type: '', user_id: '' }]
        this.form.gift_type = '1'
        this.$refs.clientSearch.resetForm()
      },
      unitChange(val, row) {
        console.log(val)
        row.goods_price = row.arr_unit.filter(
          (item) => item.id == val
        )[0].sell_price
      },
      planChange(val) {
        console.log(val)
        this.loading = true
        planSelectList({ detail_id: val }).then((res) => {
          console.log(res.data)
          this.list = res.data
          this.addRow()
          this.sumCalc()
          this.loading = false
        })
      },
      selectGoods(val, row) {
        console.log(val)
        row.goods_name = val.goods_name
        row.goods_id = val.goods_id
        row.goods_money = val.goods_money
        row.goods_num = val.quantity
        row.goods_specs = val.specs
        row.goods_remark = val.info
        row.unit_id = val.unit_id
        row.goods_price = val.goods_price
        row.type = '1'
        row.arr_unit = val.arr_unit
        row.arr_goods_type = val.arr_goods_type
        if (this.list[this.list.length - 1].goods_name !== '') {
          this.addRow()
        }
      },

      handleCopyRow(index, row) {
        this.list.splice(index, 0, JSON.parse(JSON.stringify(row)))
      },
      handleDeleteRow(index, row) {
        if (row.goods_name == '') {
          console.log('123')
          this.$message.error('不能删除此行')
          return false
        }
        if (this.list.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.list.splice(index, 1)
        }
      },
      priceInput(val, row) {
        console.log(val, row)
        let vPrice = Number(val)
        let vNum = Number(row.goods_num)
        if (vPrice != NaN && vNum != NaN) {
          row.goods_money = vPrice * vNum
        }
        this.sumCalc()
      },
      sumCalc() {
        let sum = 0
        this.list.forEach((item) => {
          if (item.goods_id !== '' && item.type == 1) {
            sum += Number(item.goods_money)
          }
        })
        this.form.total_amount = Number(sum).toFixed(2)
      },
      numInput(val, row) {
        console.log(val)
        let vNum = Number(val)
        let vPrice = Number(row.goods_price)
        if (vPrice != NaN && vNum != NaN) {
          row.goods_money = vPrice * vNum
        }
        this.sumCalc()
      },
      handleSave(type) {
        // 处理 表格数据
        let goodsData = JSON.parse(JSON.stringify(this.list))
        goodsData.forEach((item, index) => {
          if (item.goods_name == '') {
            goodsData.splice(index, 1)
          } else {
            if (item.isAdd) {
              try {
                item.unit_name = item.arr_unit.filter(
                  (item2) => item2.id == item.unit_id
                )
                if (item.unit_name.length !== 0) {
                  item.unit_name = item.unit_name[0].unit_name
                } else {
                  item.unit_name = ''
                }
                throw new Error('1')
              } catch (e) {}
            }
          }
        })
        console.log(goodsData)
        // 处理pay_data
        let payData = JSON.parse(JSON.stringify(this.form.pay_data))
        console.log(payData)
        payData.forEach((item_, index_) => {
          if (item_.money == '') {
            payData.splice(index_, 1)
          }
        })
        console.log(payData)
        let data = Object.assign(JSON.parse(JSON.stringify(this.form)), {
          check_status: type,
          goods_data: JSON.stringify(goodsData),
          pay_data: JSON.stringify(payData),
        })
        console.log(data)
        addClientPre(data).then((res) => {
          console.log(res)
          if (res.code == 200) {
            console.log('succ')
            this.$emit('refresh')
            this.$message.success(res.msg)
            this.close()
            this.clearForm()
          }
        })
      },
      close() {
        this.showDialog = false
      },
      addInput() {
        this.form.pay_data.push({ money: '', pay_type: '' })
      },
      addRows(val) {
        // val.forEach((item) => {
        //   item.goods_num = 0
        //   item.type = '1'
        //   this.list.push(item)
        // })

        console.log('添加rows')
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            item.type = '1'
            item.arr_unit.forEach((unit) => {
              if (unit.is_default == 2) {
                item.unit_id = unit.id
                item.goods_price = unit.sell_price
              }
            })
            if (item.unit_id == '') {
              item.arr_unit.forEach((unit) => {
                if (unit.unit_type == 1) {
                  item.unit_id = unit.id
                  item.goods_price = unit.sell_price
                }
              })
            }
            this.list.splice(this.list.length - 1, 0, item)
          })
          this.$message.success('添加成功')
        }
      },
      addRow() {
        this.list.push({
          id: '',
          type: '1',
          goods_id: '',
          goods_name: '',
          goods_specs: '',
          unit_id: '',
          unit_name: '',
          goods_price: '',
          goods_num: '0',
          delete_at: '0',
          isAdd: true,
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .addbtn {
    position: absolute;
    top: 0;
    right: -55px;
    margin-right: 15px;
    margin-left: 15px;
    font-size: 32px;
    color: #84bc39;
  }
</style>
