<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="审核"
    :visible.sync="showDialog"
    top="5vh"
    width="1160px"
    @close="close"
  >
    <div v-loading="loading" class="wrapper">
      <el-alert
        :closable="false"
        :title="titleTip"
        type="success"
        style="width: 86%; margin-top: -70px; margin-left: 100px"
      ></el-alert>
      <div class="orderStatus">{{ orderState }}</div>
      <el-form :model="data" inline label-width="90px">
        <el-form-item label="客户名称" prop="cust_name">
          <el-input v-model="data.cust_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact">
          <el-input v-model.trim="data.contact" disabled></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address" class="dz">
          <el-input
            v-model.trim="data.address"
            disabled
            style="width: 380px"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input v-model="data.info_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="业务员">
          <el-input v-model="data.user_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="签单金额">
          <el-input v-model="data.total_amount" disabled></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="已收款">
          <el-input v-model="data.receive_amount" disabled></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="data.remark"
            disabled
            style="width: 500px"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="table">
        <div style="line-height: 30px">存货详情</div>
        <el-table
          stripe
          :data="inventoryData"
          :border="true"
          :summary-method="getSummaries"
          :show-summary="true"
        >
          <el-table-column
            v-for="(item, index) in inventoryColumns"
            :key="index"
            :prop="item.prop"
            :width="item.width"
            :label="item.label"
            align="center"
          >
            <template #default="{ row }">
              <template v-if="item.label == '商品名称'">
                <goods-search
                  ref="goodsSearch"
                  :f-key="row.goods_name_print"
                  @add-rows="addRows"
                  @select-goods-all="selectGoods($event, row)"
                ></goods-search>
              </template>
              <template v-else-if="item.label == '类型'">
                <el-select v-model="row.type">
                  <el-option
                    v-for="(typeItem, typeIndex) in typeList"
                    :key="typeIndex"
                    :label="typeItem.value"
                    :value="typeItem.id"
                  ></el-option>
                </el-select>
              </template>
              <template v-else-if="item.label == '单位'">
                <el-select
                  v-model="row.unit_id"
                  @change="unitChange($event, row)"
                >
                  <el-option
                    v-for="(unitItem, unitIndex) in row.arr_unit"
                    :key="unitIndex"
                    :label="unitItem.unit_name"
                    :value="unitItem.id"
                  ></el-option>
                </el-select>
              </template>
              <template v-else-if="item.label == '存货价'">
                <el-input
                  v-model.number="row.goods_price"
                  type="number"
                  @input="priceInput($event, row)"
                ></el-input>
              </template>
              <template v-else-if="item.label == '存货数量'">
                <el-input
                  v-model.number="row.goods_num"
                  type="number"
                  @input="numInput($event, row)"
                ></el-input>
              </template>
              <template v-else-if="item.label == '金额'">
                <el-input v-model.number="row.goods_money"></el-input>
              </template>
              <template v-else-if="item.label == '备注'"></template>
              <template v-else>{{ row[item.prop] }}</template>
            </template>
            <!-- <template #default="{ row }">
              <div v-if="item.label == '存货价'">
                <el-input
                  v-model="row.goods_price"
                  @input="moneyN(row)"
                ></el-input>
              </div>
              <div v-else-if="item.label == '存货数量'">
                <el-input
                  v-model="row.goods_num"
                  @input="moneyN(row)"
                ></el-input>
              </div>
              <div v-else-if="item.label == '备注'">
                <el-input v-model="row.goods_remark"></el-input>
              </div>
              <div v-else>
                {{ row[item.prop] }}
              </div>
            </template> -->
          </el-table-column>
          <el-table-column
            prop=""
            align="center"
            label="操作"
            fixed="right"
            min-width="160px"
          >
            <template #default="{ $index, row }">
              <el-button
                type="text"
                @click.native.prevent="handleAddRow($index, row)"
              >
                添加
              </el-button>
              <el-button
                type="text"
                @click.native.prevent="handleCopyRow($index, row)"
              >
                复制
              </el-button>

              <el-button
                type="text"
                @click.native.prevent="handleDeleteRow($index, row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="line-height: 30px">收款记录</div>
        <base-table
          :list.sync="backData"
          :columns.sync="backColumns"
          :show-summary="true"
          :is-border="true"
          :summary="backSummary"
        ></base-table>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="handleReview">审核</el-button>
      <el-button>打印</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { clientRowDetail, rowReview } from '@/api/createPreInventory'
  import BaseTable from '@/baseComponents/baseTable'
  import GoodsSearch from '@/baseComponents/goodsSearch'

  export default {
    name: 'Review',
    components: {
      BaseTable,
      GoodsSearch,
    },
    data() {
      return {
        id: 0,
        orderState: '状态',
        showDialog: false,
        loading: true,
        data: {},
        inventoryData: [],
        inventoryColumns: [
          {
            width: '',
            label: '类型',
            prop: 'type_text',
          },
          {
            width: '210',
            label: '商品名称',
            prop: 'goods_name_print',
          },
          {
            width: '',
            label: '规格',
            prop: 'goods_specs',
          },

          {
            width: '',
            label: '单位',
            prop: 'unit_name',
          },
          {
            width: '',
            label: '存货价',
            prop: 'goods_price',
          },
          {
            width: '',
            label: '存货数量',
            prop: 'goods_num',
          },
          {
            width: '',
            label: '金额',
            prop: 'goods_money',
          },
          {
            width: '',
            label: '备注',
            prop: 'goods_remark',
          },
        ],
        inventorySummary: [],
        backData: [],
        backColumns: [
          {
            width: '210',
            prop: 'create_at',
            label: '时间',
          },
          {
            width: '210',
            prop: 'pay_type',
            label: '收款方式',
          },

          {
            width: '210',
            prop: 'amount',
            label: '金额',
          },
          {
            width: '210',
            prop: 'user_name',
            label: '收款人',
          },
        ],
        backSummary: [],
        typeList: [
          {
            id: '1',
            value: '销常',
          },
          {
            id: '4',
            value: '赠品',
          },
        ],
      }
    },
    computed: {
      titleTip() {
        return '单号:' + this.data.bill_code + ' 签单:' + this.data.create_at
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await clientRowDetail({
          id: this.id,
          is_check: 1,
        })
        if (code == 200) {
          console.log(data)
          this.data = data
          if (
            Object.prototype.toString.call(
              this.data.summary_log.summary_list
            ) == '[object Array]'
          ) {
            this.data.summary_log.summary_list.forEach((i) => {
              i.type = i.type + ''
            })
            if (this.data.summary_log.summary_list.length == 0) {
              this.data.summary_log.summary_list.push({
                id: '',
                type: '1',
                goods_id: '',
                goods_name: '',
                goods_name_print: '',
                goods_specs: '',
                unit_id: '',
                unit_name: '',
                goods_price: '',
                goods_num: '0',
                delete_at: '0',
                isAdd: true,
              })
            }
          }

          let a = ''
          if (data.check_status == 0) {
            a = '未审核'
          } else if (data.check_status == 1) {
            a = '已审核'
          } else if (data.check_status == 2) {
            a = '已终止'
          }
          this.orderState = a
          // 库存表格数据
          this.inventoryData = this.data.summary_log.summary_list
          // this.inventorySummary = [
          //   { idx: 0, info: '合计' },
          //   {
          //     idx: 5,
          //     info: this.data.summary_log.summary_total_unit,
          //   },
          //   {
          //     idx: 6,
          //     info: this.data.summary_log.summary_total_unit,
          //   },
          // ]
          // 收款记录
          this.backData = this.data.collection_log.collection_list
          this.backSummary = [
            { idx: 0, info: '合计' },
            {
              idx: 2,
              info: this.data.collection_log.collection_total_amount,
            },
          ]
        }
        this.loading = false
      },
      close() {
        this.showDialog = false
      },
      moneyN(row) {
        if (row.goods_num == '') {
          row.goods_num = 0
          row.goods_money = 0
          return
        }
        if (row.goods_price == '') {
          row.goods_price = 0
          row.goods_money = 0
          return
        }
        row.goods_money = (
          parseFloat(row.goods_price) * parseFloat(row.goods_num)
        ).toFixed(4)
      },
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        let num = 0
        data.map((item) => (num += Number(item.goods_money)))
        if (!num) {
          num = 0
        }
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总价'
            return
          }
          if (index === 6) {
            sums[index] = num.toFixed(4)
            return
          }
        })
        return sums
      },
      handleReview() {
        let list = this.data.summary_log.summary_list
        let list2 = []
        list.forEach((i) => {
          if (i.goods_id) {
            i.unit_name =
              i.arr_unit.filter((u) => u.id == i.unit_id)[0].unit_name || ''
            list2.push(i)
          }
        })
        list2 = list2.length == 0 ? '' : JSON.stringify(list2)
        rowReview({
          order_id: this.id,
          goods_data: list2,
        }).then((res) => {
          if (res.code == 200) {
            this.showDialog = false
            this.$message.success(res.msg)
            this.$emit('refresh')
          }
        })
      },
      priceInput(val, row) {
        console.log(val, row)
        let vPrice = Number(val)
        let vNum = Number(row.goods_num)
        if (vPrice != NaN && vNum != NaN) {
          row.goods_money = vPrice * vNum
        }
        this.sumCalc()
      },
      numInput(val, row) {
        console.log(val)
        let vNum = Number(val)
        let vPrice = Number(row.goods_price)
        if (vPrice != NaN && vNum != NaN) {
          row.goods_money = vPrice * vNum
        }
        this.sumCalc()
      },
      sumCalc() {
        let sum = 0
        this.data.summary_log.summary_list.forEach((item) => {
          if (item.goods_id !== '' && item.type == 1) {
            sum += Number(item.goods_money)
          }
        })
        this.data.total_amount = sum
      },
      unitChange(val, row) {
        console.log(val)
        row.goods_price = row.arr_unit.filter(
          (item) => item.id == val
        )[0].sell_price
      },
      selectGoods(val, row) {
        if (val.goods_id > 0) {
          console.log(val)
          row.goods_name = val.goods_name
          row.goods_name_print = val.goods_name_print
          row.goods_id = val.goods_id
          row.goods_money = val.goods_money
          row.goods_num = val.quantity
          row.goods_specs = val.specs
          row.goods_remark = val.info
          row.unit_id = val.unit_id
          row.goods_price = val.goods_price

          row.type = '1'
          row.arr_unit = val.arr_unit
          row.arr_goods_type = val.arr_goods_type
          if (
            this.data.summary_log.summary_list[
              this.data.summary_log.summary_list.length - 1
            ].goods_name_print !== ''
          ) {
            this.addRow()
          }
        }
      },
      handleCopyRow(index, row) {
        this.data.summary_log.summary_list.splice(
          index,
          0,
          JSON.parse(JSON.stringify(row))
        )
      },
      handleDeleteRow(index) {
        if (this.data.summary_log.summary_list.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.data.summary_log.summary_list.splice(index, 1)
        }
      },
      addRow() {
        this.data.summary_log.summary_list.push({
          id: '',
          type: '1',
          goods_id: '',
          goods_name_print: '',
          goods_name: '',
          goods_specs: '',
          unit_id: '',
          unit_name: '',
          goods_price: '',
          goods_num: '0',
          delete_at: '0',
          isAdd: true,
        })
      },
      handleAddRow(index) {
        this.data.summary_log.summary_list.splice(index + 1, 0, {
          id: '',
          type: '1',
          goods_id: '',
          goods_name: '',
          goods_name_print: '',
          goods_specs: '',
          unit_id: '',
          unit_name: '',
          goods_price: '',
          goods_num: '0',
          delete_at: '0',
          isAdd: true,
        })
      },
      addRows(val) {
        val.forEach((item) => {
          item.goods_num = 0
          item.type = '1'
          this.data.summary_log.summary_list.push(item)
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
