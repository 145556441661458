var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.form, inline: "" } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "info_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "160px" },
                  attrs: {
                    placeholder: "请选择预存活动",
                    "popper-class": "select-idx",
                    clearable: "",
                  },
                  model: {
                    value: _vm.form.info_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "info_id", $$v)
                    },
                    expression: "form.info_id",
                  },
                },
                _vm._l(_vm.info_id_list, function (item) {
                  return _c("el-option", {
                    key: item.info_id,
                    attrs: { value: item.info_id, label: item.info_name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "is_create" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { placeholder: "请选择类型", clearable: "" },
                  model: {
                    value: _vm.form.is_create,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_create", $$v)
                    },
                    expression: "form.is_create",
                  },
                },
                _vm._l(_vm.is_create_list, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { value: item.value, label: item.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("client-search", {
                ref: "clientSearch",
                attrs: { "popper-class": "select-idx selectDC" },
                on: { "select-id": _vm.selectId },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "staff_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { placeholder: "请选择业务员", clearable: "" },
                  model: {
                    value: _vm.form.staff_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "staff_id", $$v)
                    },
                    expression: "form.staff_id",
                  },
                },
                _vm._l(_vm.staff_id_list, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "is_left" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { placeholder: "收款情况", clearable: "" },
                  model: {
                    value: _vm.form.is_left,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_left", $$v)
                    },
                    expression: "form.is_left",
                  },
                },
                _vm._l(_vm.is_left_list, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "check_status" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { placeholder: "状态", clearable: "" },
                  model: {
                    value: _vm.form.check_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "check_status", $$v)
                    },
                    expression: "form.check_status",
                  },
                },
                _vm._l(_vm.check_status_list, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleQuery } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
                [_vm._v("添加客户预存货")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            stripe: "",
            data: _vm.list,
            "show-summary": "",
            "summary-method": _vm.summaryFunction,
          },
          on: { "selection-change": _vm.handlerSelection },
        },
        [
          _c(
            "el-table-column",
            { attrs: { align: "center", type: "index", width: "50" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "custom-table-checkbox",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.columns, function (item, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: item.label },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference",
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { icon: "settings-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "50" },
          }),
          _vm._l(_vm.finallyColumn, function (item, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                label: item.label,
                prop: item.prop,
                width: "",
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label == "异常提醒"
                          ? _c("div", [
                              row.is_change == 1
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("异常")]
                                  )
                                : _c("span", [_vm._v("正常")]),
                            ])
                          : _c("div", [
                              _vm._v(" " + _vm._s(row[item.prop]) + " "),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              align: "center",
              label: "操作",
              fixed: "right",
              "min-width": "160px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    row.check_status == 3
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleCheck($index, row)
                                },
                              },
                            },
                            [_vm._v(" 查看 ")]
                          ),
                          row.check_status != 2
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handlePrint($index, row)
                                    },
                                  },
                                },
                                [_vm._v(" 打印 ")]
                              )
                            : _vm._e(),
                        ]
                      : [
                          row.check_status == 4
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleCheck($index, row)
                                        },
                                      },
                                    },
                                    [_vm._v(" 查看 ")]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  row.check_status == 1 ||
                                  row.check_status == 2 ||
                                  row.check_status == 3
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleCheck(
                                                $index,
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 查看 ")]
                                      )
                                    : _vm._e(),
                                  row.check_status == 1
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleEdit(row)
                                            },
                                          },
                                        },
                                        [_vm._v(" 编辑 ")]
                                      )
                                    : _vm._e(),
                                  row.check_status == 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleReview(
                                                $index,
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 审核 ")]
                                      )
                                    : _vm._e(),
                                  row.check_status == 1
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleShouKuan(row)
                                            },
                                          },
                                        },
                                        [_vm._v(" 收款 ")]
                                      )
                                    : _vm._e(),
                                  row.check_status != 2 &&
                                  row.check_status !== 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleBack($index, row)
                                            },
                                          },
                                        },
                                        [_vm._v(" 还货 ")]
                                      )
                                    : _vm._e(),
                                  row.is_use == 1
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleReturn(
                                                $index,
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 退货 ")]
                                      )
                                    : _vm._e(),
                                  row.check_status != 2
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handlePrint(
                                                $index,
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 打印 ")]
                                      )
                                    : _vm._e(),
                                  row.check_status == 1
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleTermination(
                                                $index,
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 终止 ")]
                                      )
                                    : _vm._e(),
                                  row.check_status == 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleDelete(
                                                $index,
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 作废 ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                        ],
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.totalCount,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.handlerBatchChangeStaff },
            },
            [_vm._v(" 更换业务员 ")]
          ),
        ],
        1
      ),
      _c("check", { ref: "check", on: { refresh: _vm.fetchData } }),
      _c("review", { ref: "review", on: { refresh: _vm.fetchData } }),
      _c("back", { ref: "back", on: { refresh: _vm.fetchData } }),
      _c("add", { ref: "add", on: { refresh: _vm.fetchData } }),
      _c("ChangeStaff", { ref: "changeStaff", on: { refresh: _vm.fetchData } }),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "确定要终止本协议吗?",
            visible: _vm.showDialog2,
            width: "500px",
            top: "20vh",
            "close-on-click-modal": false,
            "destroy-on-close": "",
            center: "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog2 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "orderTest-container" },
            [
              _c(
                "el-form",
                {
                  ref: "form2",
                  staticStyle: { padding: "0 40px" },
                  attrs: {
                    model: _vm.form2,
                    "label-position": "right",
                    "label-width": "80px",
                    "label-suffix": ":",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "签单金额", prop: "" } },
                    [_vm._v(" " + _vm._s(_vm.form2.total_amount) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "累计收款", prop: "" } },
                    [_vm._v(" " + _vm._s(_vm.form2.receive_amount) + " ")]
                  ),
                  _c("el-form-item", { attrs: { label: "已下单", prop: "" } }, [
                    _vm._v(" " + _vm._s(_vm.form2.already_amount) + " "),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "可退金额", prop: "" } },
                    [_vm._v(" " + _vm._s(_vm.form2.back_amount) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "退款", prop: "" } },
                    [
                      _c("el-input", {
                        attrs: { type: "number" },
                        model: {
                          value: _vm.form2.return_money,
                          callback: function ($$v) {
                            _vm.$set(_vm.form2, "return_money", $$v)
                          },
                          expression: "form2.return_money",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "退款方式" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.form2.pay_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form2, "pay_type", $$v)
                            },
                            expression: "form2.pay_type",
                          },
                        },
                        _vm._l(
                          _vm.allSelectList.arr_pay_type,
                          function (list_) {
                            return _c("el-option", {
                              key: list_.id,
                              attrs: { value: list_.id, label: list_.name },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "margin-top": "10px", "text-align": "right" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleStop2 },
                    },
                    [_vm._v("终止并退款")]
                  ),
                  _c("el-button", { on: { click: _vm.close2 } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "YH", data_id: _vm.data_id, type: 1 },
      }),
      _c("shou-kuan2", { ref: "shoukuan", on: { refresh: _vm.handleQuery } }),
      _c("returnOrder", {
        ref: "returnOrder",
        on: { refresh: _vm.handleQuery },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }