<template>
  <!-- NAME[epic=动销] 客户预存货单据 -->
  <div class="orderTest-container">
    <el-form :model="form" inline>
      <el-form-item prop="info_id">
        <el-select
          v-model="form.info_id"
          placeholder="请选择预存活动"
          :popper-class="'select-idx'"
          clearable
          style="width: 160px"
        >
          <el-option
            v-for="item in info_id_list"
            :key="item.info_id"
            :value="item.info_id"
            :label="item.info_name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="is_create">
        <el-select
          v-model="form.is_create"
          placeholder="请选择类型"
          clearable
          style="width: 140px"
        >
          <el-option
            v-for="item in is_create_list"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <client-search
          ref="clientSearch"
          :popper-class="'select-idx selectDC'"
          @select-id="selectId"
        ></client-search>
      </el-form-item>

      <el-form-item prop="staff_id">
        <el-select
          v-model="form.staff_id"
          placeholder="请选择业务员"
          clearable
          style="width: 120px"
        >
          <el-option
            v-for="item in staff_id_list"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 收款情况 -->
      <el-form-item prop="is_left">
        <el-select
          v-model="form.is_left"
          placeholder="收款情况"
          clearable
          style="width: 120px"
        >
          <el-option
            v-for="item in is_left_list"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="check_status">
        <el-select
          v-model="form.check_status"
          placeholder="状态"
          clearable
          style="width: 120px"
        >
          <el-option
            v-for="item in check_status_list"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleAdd">添加客户预存货</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :summary-method="summaryFunction"
      @selection-change="handlerSelection"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, index) in finallyColumn"
        :key="index"
        :label="item.label"
        :prop="item.prop"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '异常提醒'">
            <span v-if="row.is_change == 1" style="color: red">异常</span>
            <span v-else>正常</span>
          </div>
          <!-- <div v-else-if="item.label == '状态'">
            <span v-if="row.check_status == 0">未审核</span>
            <span v-else-if="row.check_status == 1">已审核</span>
            <span v-else-if="row.check_status == 2">已终止</span>
          </div> -->
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <!-- =========表格操作 -->
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <template v-if="row.check_status == 3">
            <!-- 已完成 -->
            <el-button
              type="text"
              @click.native.prevent="handleCheck($index, row)"
            >
              查看
            </el-button>
            <el-button
              v-if="row.check_status != 2"
              type="text"
              @click.native.prevent="handlePrint($index, row)"
            >
              打印
            </el-button>
          </template>
          <template v-else>
            <div v-if="row.check_status == 4">
              <el-button
                type="text"
                @click.native.prevent="handleCheck($index, row)"
              >
                查看
              </el-button>
            </div>
            <div v-else>
              <el-button
                v-if="
                  row.check_status == 1 ||
                  row.check_status == 2 ||
                  row.check_status == 3
                "
                type="text"
                @click.native.prevent="handleCheck($index, row)"
              >
                查看
              </el-button>
              <el-button
                v-if="row.check_status == 1"
                type="text"
                @click.native.prevent="handleEdit(row)"
              >
                编辑
              </el-button>
              <el-button
                v-if="row.check_status == 0"
                type="text"
                @click.native.prevent="handleReview($index, row)"
              >
                审核
              </el-button>

              <el-button
                v-if="row.check_status == 1"
                type="text"
                @click.native.prevent="handleShouKuan(row)"
              >
                收款
              </el-button>
              <el-button
                v-if="row.check_status != 2 && row.check_status !== 0"
                type="text"
                @click.native.prevent="handleBack($index, row)"
              >
                还货
              </el-button>
              <el-button
                v-if="row.is_use == 1"
                type="text"
                @click.native.prevent="handleReturn($index, row)"
              >
                退货
              </el-button>
              <el-button
                v-if="row.check_status != 2"
                type="text"
                @click.native.prevent="handlePrint($index, row)"
              >
                打印
              </el-button>
              <el-button
                v-if="row.check_status == 1"
                type="text"
                @click.native.prevent="handleTermination($index, row)"
              >
                终止
              </el-button>
              <el-button
                v-if="row.check_status == 0"
                type="text"
                @click.native.prevent="handleDelete($index, row)"
              >
                作废
              </el-button>
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="totalCount"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <el-row type="flex" class="row-bg">
      <el-button type="primary" @click="handlerBatchChangeStaff">
        更换业务员
      </el-button>
    </el-row>
    <check ref="check" @refresh="fetchData"></check>
    <review ref="review" @refresh="fetchData"></review>
    <back ref="back" @refresh="fetchData"></back>
    <add ref="add" @refresh="fetchData"></add>
    <ChangeStaff ref="changeStaff" @refresh="fetchData"></ChangeStaff>

    <!-- 终止弹框 -->
    <el-dialog
      :modal="false"
      title="确定要终止本协议吗?"
      :visible.sync="showDialog2"
      width="500px"
      top="20vh"
      :close-on-click-modal="false"
      destroy-on-close
      center
      append-to-body
    >
      <div class="orderTest-container">
        <el-form
          ref="form2"
          :model="form2"
          label-position="right"
          label-width="80px"
          label-suffix=":"
          style="padding: 0 40px"
        >
          <el-form-item label="签单金额" prop="">
            <!-- <el-input v-model="form2.total_amount" disabled></el-input> -->
            {{ form2.total_amount }}
          </el-form-item>
          <el-form-item label="累计收款" prop="">
            <!-- <el-input v-model="form2.receive_amount" disabled></el-input> -->
            {{ form2.receive_amount }}
          </el-form-item>
          <el-form-item label="已下单" prop="">
            <!-- <el-input v-model="form2.already_amount" disabled></el-input> -->
            {{ form2.already_amount }}
          </el-form-item>
          <el-form-item label="可退金额" prop="">
            <!-- <el-input v-model="form2.back_amount" disabled></el-input> -->
            {{ form2.back_amount }}
          </el-form-item>
          <el-form-item label="退款" prop="">
            <el-input v-model="form2.return_money" type="number"></el-input>
          </el-form-item>
          <el-form-item label="退款方式">
            <el-select v-model="form2.pay_type">
              <el-option
                v-for="list_ in allSelectList.arr_pay_type"
                :key="list_.id"
                :value="list_.id"
                :label="list_.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div style="margin-top: 10px; text-align: right">
          <!-- <el-button type="warning" @click="handleStop1">
            终止协议无需退款
          </el-button> -->
          <el-button type="primary" @click="handleStop2">终止并退款</el-button>
          <el-button @click="close2">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="YH"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
    <shou-kuan2 ref="shoukuan" @refresh="handleQuery"></shou-kuan2>
    <returnOrder ref="returnOrder" @refresh="handleQuery"></returnOrder>
  </div>
</template>
<script>
  import _ from 'lodash'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { getAllSelect } from '@/api/advanceOrder'

  // 业务员下拉
  import { getStaffList } from '@/api/setPrice'
  import {
    activeSelectList,
    planSelectList,
    orderList,
    stopActive,
  } from '@/api/createPreInventory'
  import ClientSearch from '@/baseComponents/clientSearch'
  import Check from './check'
  import Review from './review'
  import Back from './back'
  import Add from './add'
  import returnOrder from './return'
  import { postAction } from '@/api/Employee'
  import shouKuan2 from '@/views/project/movablepin/predeposit/depositreceipt/components/components/shouKuan'
  import ChangeStaff from '@/views/project/finance/client/arrears/components/changeStaff.vue'

  export default {
    name: 'ClientDoc',
    components: {
      ClientSearch,
      Check,
      Review,
      Back,
      Add,
      RuilangDisplayDialog,
      shouKuan2,
      returnOrder,
      ChangeStaff,
    },
    beforeRouteEnter(to, from, next) {
      console.log(to, '预存货')
      next((vm) => {
        console.log(vm)
        let querys = to.query
        if (querys.custId) vm.form.cust_id = querys.custId
        if (querys.is_left) vm.form.is_left = Number(querys.is_left)
        if (querys.name) vm.$refs.clientSearch.kw = querys.name
      })
    },
    data() {
      return {
        data: {},
        sum: [],
        data_id: null,
        currentRow: 0,
        loading: true,
        showDialog2: false,
        allSelectList: {},
        totalCount: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          pageNo: 1,
          pageSize: 10,
          info_id: '', //活动ID
          is_create: '', //活动类型1参加活动2业务员
          cust_name: '', //客户名称，助记码
          cust_id: '',
          staff_id: '', //业务员id
          is_left: '', //收款情况1未结清2已结清
          check_status: '', //状态0未审核1已审核2已终止
        },
        form2: {
          pay_type: '',

          return_money: '',
          total_amount: '', //签单金额
          receive_amount: '', //收款金额
          already_amount: '', //下单金额
          back_amount: '', //可退金额
        },
        info_id_list: [], //活动
        is_create_list: [
          {
            //活动类型1参加活动2业务员
            label: '参加活动',
            value: 1,
          },
          {
            label: '业务员创建',
            value: 2,
          },
        ],
        staff_id_list: [],
        is_left_list: [
          //收款情况1未结清2已结清
          {
            name: '未结清',
            id: 1,
          },
          {
            name: '已结清',
            id: 2,
          },
        ],
        check_status_list: [
          //状态0未审核1已审核2已终止
          {
            name: '待审核',
            id: 0,
          },
          {
            name: '已审核',
            id: 1,
          },
          {
            name: '已完成',
            id: 3,
          },
          {
            name: '已终止',
            id: 2,
          },
          {
            name: '已作废',
            id: 4,
          },
        ],
        list: [],
        checkList: [
          '单号',
          '终端名称',
          '类型',
          '活动名称',
          '签单金额',
          '累计收款',
          '待收款',
          '存货商品',
          '业务员',
          '异常提醒',
          '状态',
        ],

        columns: [
          {
            order: 1,
            label: '单号',
            prop: 'bill_code',
            width: 'auto',
          },
          {
            order: 2,
            label: '终端名称',
            prop: 'cust_name',
            width: 'auto',
          },
          {
            order: 3,
            label: '类型',
            prop: 'crete_name',
            width: 'auto',
          },
          {
            order: 4,
            label: '活动名称',
            prop: 'name',
            width: 'auto',
          },
          {
            order: 5,
            label: '签单金额',
            prop: 'total_amount',
            width: 'auto',
          },
          {
            order: 6,
            label: '累计收款',
            prop: 'receive_amount',
            width: 'auto',
          },
          {
            order: 6.5,
            label: '待收款',
            prop: 'arrears_amount',
            width: 'auto',
          },
          {
            order: 7,
            label: '存货商品',
            prop: 'goods_count',
            width: 'auto',
          },
          {
            order: 8,
            label: '业务员',
            prop: 'staff_user',
            width: 'auto',
          },
          {
            order: 9,
            label: '异常提醒',
            prop: 'is_change',
            width: 'auto',
          },
          {
            order: 10,
            label: '状态',
            prop: 'check_status_text',
            width: 'auto',
          },
        ],
      }
    },
    computed: {
      finallyColumn() {
        let arr = []
        this.checkList.forEach((item) => {
          arr.push(this.columns.filter((item2) => item2.label == item)[0])
        })
        return _.sortBy(arr, (item) => arr.order)
      },
    },
    watch: {
      showDialog2(val) {
        if (!val) {
          this.form2 = {
            total_amount: '', //签单金额
            receive_amount: '', //收款金额
            already_amount: '', //下单金额
            back_amount: '', //可退金额
          }
        }
      },
    },
    created() {
      // 预存货活动下拉
      activeSelectList().then((res) => {
        this.info_id_list = res.data
      })
      // 业务员下拉
      getStaffList().then((res) => {
        this.staff_id_list = res.data
      })
      this.getAllSelectList()
    },
    mounted() {
      // 获取url参数
      console.log('获取url参数', this.$route.query.check_status)
      if (this.$route.query.check_status) {
        this.form.check_status = Number(this.$route.query.check_status)
        this.fetchData()
      } else {
        this.fetchData()
      }
    },
    methods: {
      handlerSelection(val) {
        console.log(val, '选完后的')
        this.sum = val
      },
      handlerBatchChangeStaff() {
        if (this.sum.length > 0) {
          let ids = []
          this.sum.forEach((i) => {
            if (i.id) ids.push(i.id)
          })
          this.$refs.changeStaff.type = 2
          this.$refs.changeStaff.ids = ids
          this.$refs.changeStaff.showDialog = true
        } else {
          this.$message({
            message: '请勾选单据',
            type: 'info',
          })
        }
      },
      async getAllSelectList() {
        let { data, code, msg } = await getAllSelect()
        if (code == 200) {
          console.log(data)
          this.allSelectList = data
          let a = this.allSelectList.arr_pay_type[0].id
          this.$set(this.form2, 'pay_type', a)
        }
      },
      // 获取表格数据
      async fetchData() {
        this.loading = true
        let { data, msg, code, totalCount } = await orderList(this.form)
        if (code == 200) {
          this.data = data
          this.list = data.list
          this.totalCount = totalCount
        }
        this.loading = false
      },
      // 选择客户组件输入事件
      clientChoose(val) {
        this.form.cust_name = val
      },
      selectId(val) {
        console.log('selectId')
        console.log(val)
        if (val.cust_id) {
          this.form.cust_id = val
        } else {
          this.form.cust_id = val
        }
      },
      clearInput() {
        this.form.cust_id = ''
      },
      // 查询
      handleQuery() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.fetchData()
      },
      // 分页
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      // 添加客户预存款
      handleAdd() {
        this.$refs.add.showDialog = true
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [6, 7]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      // 表格内操作
      handleEdit(row) {
        this.$refs.check.id = row.id
        this.$refs.check.type = 2
        this.$refs.check.showDialog = true
      },
      handleCheck(index, row) {
        this.$refs.check.showDialog = true
        this.$refs.check.id = row.id
      },
      handleReview(index, row) {
        // <!-- 0未审核1已审核2已终止 -->
        this.$refs.review.showDialog = true
        this.$refs.review.id = row.id
      },
      handleBack(index, row) {
        this.$refs.back.showDialog = true
        this.$refs.back.id = row.id
      },
      handlePrint(index, row) {
        this.data_id = row.id
        this.$refs['RuilangDisplayDialog'].batch = false
        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
      handleTermination(index, row) {
        this.currentRow = row.id
        this.showDialog2 = true
        this.form2.total_amount = row.total_amount
        this.form2.receive_amount = row.receive_amount
        this.form2.already_amount = row.already_amount
        this.form2.back_amount = row.back_amount
        this.form2.return_money = JSON.parse(
          JSON.stringify(this.form2.back_amount)
        )
      },
      handleStop1() {
        stopActive({
          id: this.currentRow,
          money: 0,
          pay_type: this.form2.pay_type,
        }).then((res) => {
          this.$message.success(res.msg)
          this.close2()
          this.fetchData()
        })
      },
      handleStop2() {
        stopActive({
          id: this.currentRow,
          money: this.form2.return_money,
          pay_type: this.form2.pay_type,
        }).then((res) => {
          this.$message.success(res.msg)
          this.close2()
          this.fetchData()
        })
      },
      close2() {
        this.showDialog2 = false
      },
      // 作废
      handleDelete(idx, row) {
        this.$confirm('确定作废该协议吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            // 确定作废该协议吗?
            postAction('/promoteAdmin/goods-order/order-cancle', {
              id: row.id,
            }).then((r) => {
              this.$message.success('作废成功')
              this.fetchData()
            })
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消z除',
            // })
          })
      },
      handleShouKuan(row) {
        console.log(row)
        this.$refs.shoukuan.type = 2
        this.$refs.shoukuan.form = row
        this.$refs.shoukuan.showDialog = true
      },
      // 退货
      handleReturn(index, row) {
        console.log(row)
        this.$refs.returnOrder.showDialog = true
        this.$refs.returnOrder.id = row.id
      },
    },
  }
</script>
<style lang="scss" scoped></style>
