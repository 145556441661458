var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "添加客户预存货",
        top: "5vh",
        visible: _vm.showDialog,
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                inline: "",
                model: _vm.form,
                "label-width": "110px",
                "label-position": "right",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { prop: "info_id", label: "预存活动:" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        placeholder: "请选择预存活动",
                        clearable: "",
                        disabled: _vm.form.custom,
                      },
                      model: {
                        value: _vm.form.info_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "info_id", $$v)
                        },
                        expression: "form.info_id",
                      },
                    },
                    _vm._l(_vm.info_id_list, function (item) {
                      return _c("el-option", {
                        key: item.info_id,
                        attrs: { value: item.info_id, label: item.info_name },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-checkbox",
                    {
                      staticStyle: { "margin-left": "10px" },
                      model: {
                        value: _vm.form.custom,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "custom", $$v)
                        },
                        expression: "form.custom",
                      },
                    },
                    [_vm._v(" 自定义预存货 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { prop: "staff_id", label: "业务员:" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择业务员", clearable: "" },
                      model: {
                        value: _vm.form.staff_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "staff_id", $$v)
                        },
                        expression: "form.staff_id",
                      },
                    },
                    _vm._l(_vm.staff_id_list, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { value: item.id, label: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客户名称:" } },
                [
                  _c("client-search", {
                    ref: "clientSearch",
                    staticStyle: { width: "200px" },
                    attrs: {
                      "popper-class": "selectDC",
                      ziobj: {
                        is_closed: 0,
                      },
                    },
                    on: { "select-val-alldata": _vm.selectAll },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人:", prop: "contact" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.contact,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contact", $$v)
                      },
                      expression: "form.contact",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "地址:", prop: "address" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "备注:", prop: "remark" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "预存方案:", prop: "detail_id" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        placeholder: "请选择方案",
                        clearable: "",
                        disabled: _vm.form.custom,
                      },
                      on: { change: _vm.planChange },
                      model: {
                        value: _vm.form.detail_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "detail_id", $$v)
                        },
                        expression: "form.detail_id",
                      },
                    },
                    _vm._l(_vm.planList, function (item) {
                      return _c("el-option", {
                        key: item.detail_id,
                        attrs: {
                          value: item.detail_id,
                          label: item.detail_name,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { stripe: "", data: _vm.list },
                },
                [
                  _c(
                    "el-table-column",
                    { attrs: { align: "center", type: "index", width: "50" } },
                    [
                      _c(
                        "template",
                        { slot: "header" },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                "popper-class": "custom-table-checkbox",
                                trigger: "hover",
                              },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.checkList,
                                    callback: function ($$v) {
                                      _vm.checkList = $$v
                                    },
                                    expression: "checkList",
                                  },
                                },
                                _vm._l(_vm.columns, function (item, index) {
                                  return _c("el-checkbox", {
                                    key: index,
                                    attrs: { label: item.label },
                                  })
                                }),
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [
                                  _c("vab-remix-icon", {
                                    attrs: { icon: "settings-line" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: { align: "center", type: "selection", width: "50" },
                  }),
                  _vm._l(_vm.finallyColumns, function (item, tableIndex) {
                    return _c("el-table-column", {
                      key: tableIndex,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: item.width,
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                item.label == "预存商品"
                                  ? [
                                      _vm.form.custom
                                        ? _c(
                                            "div",
                                            [
                                              _c("goods-search", {
                                                ref: "goodsSearch",
                                                refInFor: true,
                                                attrs: {
                                                  "f-key": row.goods_name,
                                                },
                                                on: {
                                                  "add-rows": _vm.addRows,
                                                  "select-goods-all": function (
                                                    $event
                                                  ) {
                                                    return _vm.selectGoods(
                                                      $event,
                                                      row
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("div", [
                                            row.isAdd
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("goods-search", {
                                                      ref: "goodsSearch",
                                                      refInFor: true,
                                                      attrs: {
                                                        "f-key": row.goods_name,
                                                      },
                                                      on: {
                                                        "select-goods-all":
                                                          function ($event) {
                                                            return _vm.selectGoods(
                                                              $event,
                                                              row
                                                            )
                                                          },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(row.goods_name)
                                                  ),
                                                ]),
                                          ]),
                                    ]
                                  : item.label == "类型"
                                  ? [
                                      _c(
                                        "el-select",
                                        {
                                          model: {
                                            value: row.type,
                                            callback: function ($$v) {
                                              _vm.$set(row, "type", $$v)
                                            },
                                            expression: "row.type",
                                          },
                                        },
                                        _vm._l(
                                          _vm.typeList,
                                          function (typeItem, typeIndex) {
                                            return _c("el-option", {
                                              key: typeIndex,
                                              attrs: {
                                                label: typeItem.value,
                                                value: typeItem.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ]
                                  : item.label == "单位"
                                  ? [
                                      _vm.form.custom
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.unitChange(
                                                        $event,
                                                        row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: row.unit_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "unit_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.unit_id",
                                                  },
                                                },
                                                _vm._l(
                                                  row.arr_unit,
                                                  function (
                                                    unitItem,
                                                    unitIndex
                                                  ) {
                                                    return _c("el-option", {
                                                      key: unitIndex,
                                                      attrs: {
                                                        label:
                                                          unitItem.unit_name,
                                                        value: unitItem.id,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c("div", [
                                            row.isAdd
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.unitChange(
                                                              $event,
                                                              row
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value: row.unit_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              "unit_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row.unit_id",
                                                        },
                                                      },
                                                      _vm._l(
                                                        row.arr_unit,
                                                        function (
                                                          unitItem,
                                                          unitIndex
                                                        ) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: unitIndex,
                                                              attrs: {
                                                                label:
                                                                  unitItem.unit_name,
                                                                value:
                                                                  unitItem.id,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c("span", [
                                                  _vm._v(_vm._s(row.unit_name)),
                                                ]),
                                          ]),
                                    ]
                                  : item.label == "单价"
                                  ? [
                                      _c("el-input", {
                                        on: {
                                          input: function ($event) {
                                            return _vm.priceInput($event, row)
                                          },
                                        },
                                        model: {
                                          value: row.goods_price,
                                          callback: function ($$v) {
                                            _vm.$set(row, "goods_price", $$v)
                                          },
                                          expression: "row.goods_price",
                                        },
                                      }),
                                    ]
                                  : item.label == "预存数量"
                                  ? [
                                      _c("el-input", {
                                        on: {
                                          input: function ($event) {
                                            return _vm.numInput($event, row)
                                          },
                                        },
                                        model: {
                                          value: row.goods_num,
                                          callback: function ($$v) {
                                            _vm.$set(row, "goods_num", $$v)
                                          },
                                          expression: "row.goods_num",
                                        },
                                      }),
                                    ]
                                  : item.label == "金额"
                                  ? [
                                      _c("el-input", {
                                        model: {
                                          value: row.goods_money,
                                          callback: function ($$v) {
                                            _vm.$set(row, "goods_money", $$v)
                                          },
                                          expression: "row.goods_money",
                                        },
                                      }),
                                    ]
                                  : item.label == "备注"
                                  ? void 0
                                  : [_vm._v(_vm._s(row[item.prop]))],
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      align: "center",
                      label: "操作",
                      fixed: "right",
                      "min-width": "160px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleCopyRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 复制 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.handleDeleteRow($index, row)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-top": "10px", "margin-left": "35px" },
                  attrs: { label: "", prop: "" },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.form.exceed_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "exceed_status", $$v)
                        },
                        expression: "form.exceed_status",
                      },
                    },
                    [_vm._v(" 允许超收款下单 ")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "block", "margin-top": "15px" },
                  attrs: { label: "赠品设置:" },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.form.gift_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "gift_type", $$v)
                        },
                        expression: "form.gift_type",
                      },
                    },
                    [_vm._v("签约即送")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      model: {
                        value: _vm.form.gift_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "gift_type", $$v)
                        },
                        expression: "form.gift_type",
                      },
                    },
                    [_vm._v("全额收款后赠送")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "3" },
                      model: {
                        value: _vm.form.gift_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "gift_type", $$v)
                        },
                        expression: "form.gift_type",
                      },
                    },
                    [_vm._v("全部还货后赠送")]
                  ),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "签单金额:" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.total_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "total_amount", $$v)
                      },
                      expression: "form.total_amount",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _vm._l(_vm.form.pay_data, function (item1, index1) {
                return _c(
                  "el-form-item",
                  { key: index1, attrs: { label: "收款金额:" } },
                  [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c("el-input", {
                          staticStyle: {
                            width: "90px",
                            "margin-right": "20px",
                          },
                          model: {
                            value: item1.money,
                            callback: function ($$v) {
                              _vm.$set(
                                item1,
                                "money",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "item1.money",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "90px",
                              "margin-right": "10px",
                            },
                            model: {
                              value: item1.pay_type,
                              callback: function ($$v) {
                                _vm.$set(item1, "pay_type", $$v)
                              },
                              expression: "item1.pay_type",
                            },
                          },
                          _vm._l(
                            _vm.allSelectList.arr_pay_type,
                            function (item_1) {
                              return _c("el-option", {
                                key: item_1.id,
                                attrs: { label: item_1.name, value: item_1.id },
                              })
                            }
                          ),
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "90px" },
                            model: {
                              value: item1.user_id,
                              callback: function ($$v) {
                                _vm.$set(item1, "user_id", $$v)
                              },
                              expression: "item1.user_id",
                            },
                          },
                          _vm._l(_vm.selectPeopleList, function (item_1) {
                            return _c("el-option", {
                              key: item_1.user_id,
                              attrs: {
                                label: item_1.user_name,
                                value: item_1.user_id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    index1 == 0
                      ? _c("i", {
                          staticClass: "el-icon-circle-plus addbtn",
                          on: { click: _vm.addInput },
                        })
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleSave(1)
                },
              },
            },
            [_vm._v("保存并审核")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleSave(0)
                },
              },
            },
            [_vm._v("保存")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }